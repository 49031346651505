
import { Component, Vue, Prop } from "vue-property-decorator";
@Component({
  name: "PdfModal",
  components: {
  }
})
export default class PdfModal extends Vue {
  @Prop({ default: "" }) givenTitle!: string;
  @Prop({ default: "" }) givenPdfString!: string;
  iframeUrl: null | string = null;
  pdfBufferData: ArrayBuffer|null = null;

  config = {
    toolbar: {
      toolbarViewerLeft: { findbar: false }
    }
  };

  onClicked() {
    this.$bvModal.hide("pdfModal");
  }
  
  mounted(){
    console.log('givenPdfString', this.givenPdfString);
 }

}
