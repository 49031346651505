import { Vue, Component } from "vue-property-decorator";
import { mapState } from "vuex";
import { K12School } from "@/models/interface";
import {
    EntityType,
    entityTypeArray,
} from "@/models/schoolfinder.enums";
@Component({
    computed: { ...mapState("app", ["school"]) },
})
export default class School extends Vue {
    school!: K12School;

    get overallPerformance() {
        return this.school.overallPerformance;
    }

    get schoolAbout() {
        return this.school && this.school.schoolAbout;
    }

    /* get schoolYear() {
        return this.$store.state.app.schoolYear;
    } */

    get entityType() {
        let result;
        
        if (this.schoolAbout && this.schoolAbout.entityType.toLowerCase() === entityTypeArray[EntityType.School].name.toLowerCase()) {
            result = EntityType.School;
        }            
        else if (this.schoolAbout && this.schoolAbout.entityType.toLowerCase() === entityTypeArray[EntityType.SchoolSystem].name.toLowerCase()) {
            result = EntityType.SchoolSystem;
        }            
        else {
            result = EntityType.None;
        }
        
        return result;
    }

    get schoolSystem() {
        return this.entityType === EntityType.SchoolSystem;
    }
}